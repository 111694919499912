@import "~@/styles/variables/variables.scss";


































































































































































































































































.page-layout {
	height: 100%;
	display: flex;
	flex-direction: column;
	.content {
		flex: 1;
		height: 0;
		display: flex;
		.left {
			padding: 20px 12px;
			width: 300px;
			border-right: 1px solid #f1f1f1;
			height: 100%;
			overflow-y: scroll;
		}
		.right {
			flex: 1;
			width: 0;
			height: 100%;
			display: flex;
			flex-direction: column;
			padding: 0 12px 20px 12px;
			.search-modal {
				display: flex;
				flex-wrap: wrap;
				.search {
					margin-top: 20px;
					margin-right: 12px;
					flex-shrink: 0;
				}
			}
			.table-content {
				flex: 1;
				height: 0;
				margin-top: 12px;
				border-radius: 8px 8px 0 0;
				overflow: hidden;
			}
		}
	}
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
	.page-layout {
		.content {
			.left {
				width: 280px;
			}
		}
	}
}
